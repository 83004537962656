var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { staticClass: "moc-dashboard-searchbox", on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: { type: "year", label: "LBLYEAR", name: "year" },
                  model: {
                    value: _vm.searchParam.year,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "year", $$v)
                    },
                    expression: "searchParam.year",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-equip", {
                  attrs: { label: "LBLEQUIP", name: "equipmentCd" },
                  model: {
                    value: _vm.searchParam.equipmentCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "equipmentCd", $$v)
                    },
                    expression: "searchParam.equipmentCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "row bg-white" },
        _vm._l(_vm.stepItems, function (step, idx) {
          return _c(
            "div",
            { key: idx, staticClass: "col moc-row" },
            [
              _c("q-table", {
                staticClass: "moc-dashboard-table",
                class: idx !== 0 ? "arrow-after" : "",
                attrs: {
                  grid: "",
                  "card-class": "bg-primary text-white",
                  title:
                    step.title +
                    " (" +
                    (step.data ? step.data.length : 0) +
                    ")",
                  data: step.data,
                  columns: _vm.columns,
                  "row-key": "sopMocId",
                  "hide-header": "",
                  "hide-bottom": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mb-1 widget-chart widget-chart2 text-left rounded-borders border",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "widget-chart-content" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "widget-chart-flex bg-light-blue-4 text-white",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "widget-title q-pl-sm" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "moc-title" },
                                            [_vm._v(_vm._s(props.row.mocTitle))]
                                          ),
                                          _c("br"),
                                          _c(
                                            "span",
                                            { staticClass: "text-caption" },
                                            [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(props.row.mocNo) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "widget-subtitle" },
                                        [
                                          _c("q-btn", {
                                            attrs: {
                                              flat: "",
                                              round: "",
                                              color: "grey-1",
                                              icon: "content_paste",
                                              size: "xs",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.detailPopup(
                                                  props.row
                                                )
                                              },
                                            },
                                          }),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-caption text-black q-pr-xs",
                                            },
                                            [
                                              _c(
                                                "q-badge",
                                                { attrs: { color: "orange" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.row
                                                          .mocConfirmLevelName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  step.name === "MS00000001"
                                    ? _c(
                                        "q-list",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "q-item",
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c(
                                                    "q-item-label",
                                                    [
                                                      _c(
                                                        "q-badge",
                                                        {
                                                          attrs: {
                                                            color: "blue-grey",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$label(
                                                                  "LBL0000990"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                { attrs: { side: "" } },
                                                [
                                                  _c(
                                                    "q-item-label",
                                                    { attrs: { caption: "" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.row.mocClass
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item",
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c(
                                                    "q-item-label",
                                                    [
                                                      _c(
                                                        "q-badge",
                                                        {
                                                          attrs: {
                                                            color: "blue-grey",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$label(
                                                                  "LBL0000991"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                { attrs: { side: "" } },
                                                [
                                                  _c(
                                                    "q-item-label",
                                                    { attrs: { caption: "" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.row.initiative
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : step.name === "MS00000005"
                                    ? _c(
                                        "q-list",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "q-item",
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c(
                                                    "q-item-label",
                                                    [
                                                      props.row
                                                        .changeApprovalFlag ===
                                                      "Y"
                                                        ? _c(
                                                            "q-badge",
                                                            {
                                                              attrs: {
                                                                color: "blue",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$label(
                                                                      "LBL0000243"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      props.row
                                                        .changeApprovalFlag ===
                                                      "N"
                                                        ? _c(
                                                            "q-badge",
                                                            {
                                                              staticClass:
                                                                "blinking",
                                                              attrs: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$label(
                                                                      "LBL0000992"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "q-badge",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$label(
                                                                      "LBL0000993"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                { attrs: { side: "" } },
                                                [
                                                  _c(
                                                    "q-item-label",
                                                    { attrs: { caption: "" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.row
                                                            .changeApprovalDate
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : step.name === "MS00000010"
                                    ? _c(
                                        "q-list",
                                        { attrs: { dense: "" } },
                                        _vm._l(
                                          props.row.relatedWorks,
                                          function (item, key) {
                                            return _c(
                                              "q-item",
                                              { key: key },
                                              [
                                                _c(
                                                  "q-item-section",
                                                  [
                                                    _c(
                                                      "q-item-label",
                                                      [
                                                        _c(
                                                          "q-badge",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "blue-grey",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.mocRelatedTaskName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "q-item-section",
                                                  { attrs: { side: "" } },
                                                  [
                                                    _c(
                                                      "q-item-label",
                                                      _vm._l(
                                                        _vm.icons,
                                                        function (
                                                          icon,
                                                          iconIdx
                                                        ) {
                                                          return _c("q-icon", {
                                                            key: iconIdx,
                                                            attrs: {
                                                              size: "xs",
                                                              name: icon,
                                                              color:
                                                                Number(
                                                                  item.statusCheck
                                                                ) ===
                                                                iconIdx + 1
                                                                  ? _vm
                                                                      .ratingColors[
                                                                      iconIdx
                                                                    ]
                                                                  : "",
                                                            },
                                                          })
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : step.name === "MS00000015"
                                    ? _c(
                                        "q-list",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "q-item",
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c(
                                                    "q-item-label",
                                                    [
                                                      props.row
                                                        .prestartupCheckPunch ===
                                                      "미진행"
                                                        ? _c(
                                                            "q-badge",
                                                            {
                                                              staticClass:
                                                                "blinking",
                                                              attrs: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$label(
                                                                      "LBL0000987"
                                                                    )
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    props.row
                                                                      .prestartupCheckPunch
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : props.row
                                                            .prestartupCheckPunch ===
                                                          "진행중"
                                                        ? _c(
                                                            "q-badge",
                                                            {
                                                              attrs: {
                                                                color: "orange",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$label(
                                                                      "LBL0000987"
                                                                    )
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    props.row
                                                                      .prestartupCheckPunch
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "q-badge",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "light-green",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$label(
                                                                      "LBL0000987"
                                                                    )
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    props.row
                                                                      .prestartupCheckPunch
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item",
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c(
                                                    "q-item-label",
                                                    [
                                                      _c(
                                                        "q-badge",
                                                        {
                                                          attrs: {
                                                            color: "blue-grey",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$label(
                                                                  "LBL0000989"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                { attrs: { side: "" } },
                                                [
                                                  _c(
                                                    "q-item-label",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "800",
                                                        "font-size": "16px",
                                                      },
                                                    },
                                                    [
                                                      _c("ICountUp", {
                                                        attrs: {
                                                          startVal: 0,
                                                          endVal:
                                                            props.row
                                                              .mocPunchCnt,
                                                          decimals: 0,
                                                          duration: 4,
                                                          options: _vm.options,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        }),
        0
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }